import { inject, LOCALE_ID, NgModule, provideAppInitializer } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AbpHttpInterceptor } from 'abp-ng2-module';

import { SharedModule } from '@shared/shared.module';
import { ServiceProxyModule } from '@shared/service-proxies/service-proxy.module';
import { RootRoutingModule } from './root-routing.module';
import { AppConsts } from '@shared/AppConsts';
import { API_BASE_URL } from '@shared/service-proxies/service-proxies';

import { RootComponent } from './root.component';
import { AppInitializer } from './app-initializer';
import { TitleStrategy } from '@node_modules/@angular/router';
import { TranslatedRouteTitleStrategy } from '@shared/routing/translated-route-title.strategy';
import { providePrimeNG } from 'primeng/config';
import { PrimengIT } from './assets/primeng-locale/primeng-IT';
import { PrimeThemeBase } from './assets/primeng-theme/prime-theme-base.const';

export function getCurrentLanguage(): string {
    if (abp.localization.currentLanguage.name) {
        return abp.localization.currentLanguage.name;
    }

    // todo: Waiting for https://github.com/angular/angular/issues/31465 to be fixed.
    return 'en';
}

@NgModule({
    declarations: [RootComponent],
    bootstrap: [RootComponent],
    imports: [BrowserModule, BrowserAnimationsModule, SharedModule.forRoot(), ServiceProxyModule, RootRoutingModule],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
        provideAppInitializer(() => {
            const initializerFn = ((appInitializer: AppInitializer) => appInitializer.init())(inject(AppInitializer));
            return initializerFn();
        }),
        { provide: API_BASE_URL, useFactory: () => AppConsts.remoteServiceBaseUrl },
        {
            provide: LOCALE_ID,
            useFactory: getCurrentLanguage,
        },
        {
            provide: TitleStrategy,
            useClass: TranslatedRouteTitleStrategy,
        },
        provideHttpClient(withInterceptorsFromDi()),
        providePrimeNG({
            translation: PrimengIT,
            theme: {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                preset: PrimeThemeBase,
                options: {
                    darkModeSelector: false || 'none', // disables dark mode, TODO be implemented in future
                    cssLayer: {
                        name: 'primeng',
                        order: 'tailwind-base, primeng, tailwind-utilities',
                    },
                },
            },
        }),
    ],
})
export class RootModule {}
