export enum PermissionConsts {
    // Pages
    Pages_Users = 'Pages.Users',
    Pages_Services = 'Pages.Services',
    Pages_Role = 'Pages.Roles',
    Pages_Activities = 'Pages.Activities',
    Pages_SocialHealthRecords = 'Pages.SocialHealthRecords',
    Pages_Records = 'Pages.Records',
    Pages_SocialHealthRecordTemplates = 'Pages.SocialHealthRecordTemplates',

    // Roles
    Role_Create = 'Role.Create',
    Role_Update = 'Role.Update',
    Role_Get = 'Role.Get',
    Role_Delete = 'Role.Delete',
    Role_GetAll = 'Role.GetAll',

    // User
    User_Create = 'User.Create',
    User_Detail = 'User.Detail',
    User_Edit = 'User.Edit',
    User_Delete = 'User.Delete',
    User_Get = 'User.Get',
    User_GetAll = 'User.GetAll',
    User_UpdateMyProfile = 'User.UpdateMyProfile',

    // Services
    Service_Create = 'Service.Create',
    Service_Update = 'Service.Update',
    Service_Detail = 'Service.Detail',
    Service_List = 'Service.List',
    Service_Delete = 'Service.Delete',
    Service_Export = 'Service.Export',

    //ServiceWidget
    ServiceWidget_Create = 'ServiceWidget.Create',
    ServiceWidget_Update = 'ServiceWidget.Update',
    ServiceWidget_Detail = 'ServiceWidget.Detail',
    ServiceWidget_List = 'ServiceWidget.List',
    ServiceWidget_Delete = 'ServiceWidget.Delete',
    ServiceWidget_Export = 'ServiceWidget.Export',

    // ServiceTemplates
    ServiceTemplate_Create = 'ServiceTemplate.Create',
    ServiceTemplate_Update = 'ServiceTemplate.Update',
    ServiceTemplate_Detail = 'ServiceTemplate.Detail',
    ServiceTemplate_List = 'ServiceTemplate.List',
    ServiceTemplate_Delete = 'ServiceTemplate.Delete',
    ServiceTemplate_Export = 'ServiceTemplate.Export',

    // ServiceUsers
    ServiceUsers_Create = 'ServiceUsers.Create',
    ServiceUsers_Update = 'ServiceUsers.Update',
    ServiceUsers_Detail = 'ServiceUsers.Detail',
    ServiceUsers_List = 'ServiceUsers.List',
    ServiceUsers_Delete = 'ServiceUsers.Delete',
    ServiceUsers_Export = 'ServiceUsers.Export',

    // Organization
    Organization_Create = 'Organization.Create',
    Organization_Update = 'Organization.Update',
    Organization_Detail = 'Organization.Detail',
    Organization_List = 'Organization.List',
    Organization_Delete = 'Organization.Delete',
    Organization_Export = 'Organization.Export',

    // Activities
    Activity_Create = 'Activity.Create',
    Activity_Update = 'Activity.Update',
    Activity_Delete = 'Activity.Delete',
    Activity_List = 'Activity.List',
    OperatorActivity_List = 'OperatorActivity.List',
    Activity_Detail = 'Activity.Detail',
    Activity_Export = 'Activity.Export',
    ServiceActivity_Update = 'ServiceActivity.Update',

    // Structure
    Structure_List = 'Structure.List',
    Structure_Create = 'Structure.Create',
    Structure_Update = 'Structure.Update',

    // ServiceStructure
    ServiceStructure_Create = 'ServiceStructure.Create',
    ServiceStructure_List = 'ServiceStructure.List',
    ServiceStructure_Detail = 'ServiceStructure.Detail',
    ServiceStructure_Delete = 'ServiceStructure.Delete',

    // RolesDifference
    Role_OperatorLike = 'Role.IsAssignableAsOperator',
    Role_BeneficiaryLike = 'Role.IsAssignableAsBeneficiary',
    Role_BackOfficeLike = 'Role.IsAssignableAsBackOffice',

    // Files
    File_List = 'File.List',
    File_Detail = 'File.Detail',
    File_Create = 'File.Create',
    File_Delete = 'File.Delete',

    // SocialHealthRecords
    SocialHealthRecords_Create = 'SocialHealthRecords.Create',
    SocialHealthRecords_Update = 'SocialHealthRecords.Update',
    SocialHealthRecords_Delete = 'SocialHealthRecords.Delete',
    SocialHealthRecords_List = 'SocialHealthRecords.List',
    SocialHealthRecords_Detail = 'SocialHealthRecords.Detail',
    SocialHealthRecords_GetByUser = 'SocialHealthRecords.GetByUser',
    SocialHealthRecords_Export = 'SocialHealthRecords.Export',

    // SocialHealthRecordExtensionDates
    SocialHealthRecordExtensionDate_Create = 'SocialHealthRecordExtensionDate.Create',
    SocialHealthRecordExtensionDate_Update = 'SocialHealthRecordExtensionDate.Update',
    SocialHealthRecordExtensionDate_Delete = 'SocialHealthRecordExtensionDate.Delete',
    SocialHealthRecordExtensionDate_List = 'SocialHealthRecordExtensionDate.List',
    SocialHealthRecordExtensionDate_Detail = 'SocialHealthRecordExtensionDate.Detail',
    SocialHealthRecordExtensionDate_GetByUser = 'SocialHealthRecordExtensionDate.GetByUser',
    SocialHealthRecordExtensionDate_Export = 'SocialHealthRecordExtensionDate.Export',

    // CaregiverRecord
    CaregiverRecord_Create = 'CaregiverRecord.Create',
    CaregiverRecord_Update = 'CaregiverRecord.Update',
    CaregiverRecord_Get = 'CaregiverRecord.Get',
    CaregiverRecord_GetAll = 'CaregiverRecord.GetAll',
    CaregiverRecord_Delete = 'CaregiverRecord.Delete',
    CaregiverRecord_Export = 'CaregiverRecord.Export',

    // DoctorRecords
    DoctorRecords_Create = 'DoctorRecord.Create',
    DoctorRecord_Update = 'DoctorRecord.Update',
    DoctorRecord_Get = 'DoctorRecord.Get',
    DoctorRecord_GetAll = 'DoctorRecord.GetAll',
    DoctorRecord_Delete = 'DoctorRecord.Delete',
    DoctorRecord_Export = 'DoctorRecord.Export',

    // AdmissionResidanceAuthorizations
    AdmissionResidanceAuthorization_Create = 'AdmissionResidanceAuthorization.Create',
    AdmissionResidanceAuthorization_Update = 'AdmissionResidanceAuthorization.Update',
    AdmissionResidanceAuthorization_Get = 'AdmissionResidanceAuthorization.Get',
    AdmissionResidanceAuthorization_GetAll = 'AdmissionResidanceAuthorization.GetAll',
    AdmissionResidanceAuthorization_Delete = 'AdmissionResidanceAuthorization.Delete',

    // AdmissionRecord
    AdmissionRecord_Create = 'AdmissionRecord.Create',
    AdmissionRecord_Update = 'AdmissionRecord.Update',
    AdmissionRecord_Get = 'AdmissionRecord.Get',
    AdmissionRecord_GetAll = 'AdmissionRecord.GetAll',
    AdmissionRecord_Delete = 'AdmissionRecord.Delete',

    // Drug
    Drug_Create = 'Drug.Create',
    Drug_Update = 'Drug.Update',
    Drug_Get = 'Drug.Get',
    Drug_GetAll = 'Drug.GetAll',
    Drug_Delete = 'Drug.Delete',

    // HistoricalCase
    HistoricalCase_Create = 'HistoricalCase.Create',
    HistoricalCase_Update = 'HistoricalCase.Update',
    HistoricalCase_Get = 'HistoricalCase.Get',
    HistoricalCase_GetAll = 'HistoricalCase.GetAll',
    HistoricalCase_Delete = 'HistoricalCase.Delete',

    // PersonalAutonomy
    PersonalAutonomy_Create = 'PersonalAutonomy.Create',
    PersonalAutonomy_Update = 'PersonalAutonomy.Update',
    PersonalAutonomy_Get = 'PersonalAutonomy.Get',
    PersonalAutonomy_GetAll = 'PersonalAutonomy.GetAll',
    PersonalAutonomy_Delete = 'PersonalAutonomy.Delete',

    // AdmissionResidentialAuthorization
    AdmissionResidentialAuthorization_Create = 'AdmissionResidentialAuthorization.Create',
    AdmissionResidentialAuthorization_Update = 'AdmissionResidentialAuthorization.Update',
    AdmissionResidentialAuthorization_Get = 'AdmissionResidentialAuthorization.Get',
    AdmissionResidentialAuthorization_GetAll = 'AdmissionResidentialAuthorization.GetAll',
    AdmissionResidentialAuthorization_Delete = 'AdmissionResidentialAuthorization.Delete',

    // EducationalTraining
    EducationalTraining_Create = 'EducationalTraining.Create',
    EducationalTraining_Update = 'EducationalTraining.Update',
    EducationalTraining_Get = 'EducationalTraining.Get',
    EducationalTraining_GetAll = 'EducationalTraining.GetAll',
    EducationalTraining_Delete = 'EducationalTraining.Delete',

    // FamilyComponent
    FamilyComponent_Create = 'FamilyComponent.Create',
    FamilyComponent_Update = 'FamilyComponent.Update',
    FamilyComponent_Get = 'FamilyComponent.Get',
    FamilyComponent_GetAll = 'FamilyComponent.GetAll',
    FamilyComponent_Delete = 'FamilyComponent.Delete',

    //  HousingSituation
    HousingSituation_Create = 'HousingSituation.Create',
    HousingSituation_Update = 'HousingSituation.Update',
    HousingSituation_Get = 'HousingSituation.Get',
    HousingSituation_GetAll = 'HousingSituation.GetAll',
    HousingSituation_Delete = 'HousingSituation.Delete',

    // IncomeAllowance
    IncomeAllowance_Create = 'IncomeAllowance.Create',
    IncomeAllowance_Update = 'IncomeAllowance.Update',
    IncomeAllowance_Get = 'IncomeAllowance.Get',
    IncomeAllowance_GetAll = 'IncomeAllowance.GetAll',
    IncomeAllowance_Delete = 'IncomeAllowance.Delete',

    // ProtectionMeasure
    ProtectionMeasure_Create = 'ProtectionMeasure.Create',
    ProtectionMeasure_Update = 'ProtectionMeasure.Update',
    ProtectionMeasure_Get = 'ProtectionMeasure.Get',
    ProtectionMeasure_GetAll = 'ProtectionMeasure.GetAll',
    ProtectionMeasure_Delete = 'ProtectionMeasure.Delete',

    // SocialInvestigationRecord
    SocialInvestigationRecord_Create = 'SocialInvestigationRecord.Create',
    SocialInvestigationRecord_Update = 'SocialInvestigationRecord.Update',
    SocialInvestigationRecord_Get = 'SocialInvestigationRecord.Get',
    SocialInvestigationRecord_GetAll = 'SocialInvestigationRecord.GetAll',
    SocialInvestigationRecord_Delete = 'SocialInvestigationRecord.Delete',

    // Record
    Record_Create = 'Record.Create',
    Record_Update = 'Record.Update',
    Record_Get = 'Record.Get',
    Record_GetAll = 'Record.GetAll',
    Record_Delete = 'Record.Delete',

    // SocialHealthRecordTemplate
    SocialHealthRecordTemplate_Create = 'SocialHealthRecordTemplate.Create',
    SocialHealthRecordTemplate_Update = 'SocialHealthRecordTemplate.Update',
    SocialHealthRecordTemplate_Delete = 'SocialHealthRecordTemplate.Delete',
    SocialHealthRecordTemplate_GetAll = 'SocialHealthRecordTemplate.GetAll',
    SocialHealthRecordTemplate_Get = 'SocialHealthRecordTemplate.Get',

    // SectionObservationGrid
    SectionObservationGrid_Create = 'SectionObservationGrid.Create',
    SectionObservationGrid_Update = 'SectionObservationGrid.Update',
    SectionObservationGrid_Get = 'SectionObservationGrid.Get',
    SectionObservationGrid_GetAll = 'SectionObservationGrid.GetAll',
    SectionObservationGrid_Delete = 'SectionObservationGrid.Delete',

    // ScopeObservationGrid
    ScopeObservationGrid_Create = 'ScopeObservationGrid.Create',
    ScopeObservationGrid_Update = 'ScopeObservationGrid.Update',
    ScopeObservationGrid_Get = 'ScopeObservationGrid.Get',
    ScopeObservationGrid_GetAll = 'ScopeObservationGrid.GetAll',
    ScopeObservationGrid_Delete = 'ScopeObservationGrid.Delete',

    // CategoryObservationGrid
    CategoryObservationGrid_Create = 'CategoryObservationGrid.Create',
    CategoryObservationGrid_Update = 'CategoryObservationGrid.Update',
    CategoryObservationGrid_Get = 'CategoryObservationGrid.Get',
    CategoryObservationGrid_GetAll = 'CategoryObservationGrid.GetAll',
    CategoryObservationGrid_Delete = 'CategoryObservationGrid.Delete',

    // ObservationGrid
    ObservationGrid_Create = 'ObservationGrid.Create',
    ObservationGrid_Update = 'ObservationGrid.Update',
    ObservationGrid_Get = 'ObservationGrid.Get',
    ObservationGrid_GetAll = 'ObservationGrid.GetAll',
    ObservationGrid_Delete = 'ObservationGrid.Delete',

    // ResponseObservationGrid
    ResponseObservationGrid_Create = 'ResponseObservationGrid.Create',
    ResponseObservationGrid_Update = 'ResponseObservationGrid.Update',
    ResponseObservationGrid_Get = 'ResponseObservationGrid.Get',
    ResponseObservationGrid_GetAll = 'ResponseObservationGrid.GetAll',
    ResponseObservationGrid_Delete = 'ResponseObservationGrid.Delete',

    // SocialHealthRecordOperatorPermission
    SocialHealthRecordOperatorPermission_Create = 'SocialHealthRecordOperatorPermission.Create',
    SocialHealthRecordOperatorPermission_GetAll = 'SocialHealthRecordOperatorPermission.GetAll',
    SocialHealthRecordOperatorPermission_Delete = 'SocialHealthRecordOperatorPermission.Delete',

    // ObservationGridSectionOperatorPermission
    ObservationGridSectionOperatorPermission_GetAll = 'ObservationGridSectionOperatorPermission.GetAll',
    ObservationGridSectionOperatorPermission_Create = 'ObservationGridSectionOperatorPermission.Create',

    // ServiceAttendanceRegister
    ServiceAttendanceRegister_Create = 'ServiceAttendanceRegister.Create',
    ServiceAttendanceRegister_Update = 'ServiceAttendanceRegister.Update',
    ServiceAttendanceRegister_Detail = 'ServiceAttendanceRegister.Detail',
    ServiceAttendanceRegister_List = 'ServiceAttendanceRegister.List',
    ServiceAttendanceRegister_Delete = 'ServiceAttendanceRegister.Delete',
    ServiceAttendanceRegister_Export = 'ServiceAttendanceRegister.Export',

    // ServiceLog
    ServiceLog_Create = 'ServiceLog.Create',
    ServiceLog_Update = 'ServiceLog.Update',
    ServiceLog_Get = 'ServiceLog.Get',
    ServiceLog_GetAll = 'ServiceLog.GetAll',
    ServiceLog_Delete = 'ServiceLog.Delete',

    // Tag
    Tag_Create = 'Tag.Create',
    Tag_Update = 'Tag.Update',

    // ServiceTag
    ServiceTag_Get = 'ServiceTag.Get',
    ServiceTag_GetAll = 'ServiceTag.GetAll',
    ServiceTag_Create = 'ServiceTag.Create',
    ServiceTag_Delete = 'ServiceTag.Delete',

    // CustomDataTemplate
    CustomDataTemplate_Create = 'CustomDataTemplate.Create',
    CustomDataTemplate_Update = 'CustomDataTemplate.Update',
    CustomDataTemplate_Delete = 'CustomDataTemplate.Delete',
    CustomDataTemplate_Get = 'CustomDataTemplate.Get',
    CustomDataTemplate_GetAll = 'CustomDataTemplate.GetAll',

    // Asset
    Asset_Create = 'Asset.Create',
    Asset_Update = 'Asset.Update',
    Asset_Delete = 'Asset.Delete',
    Asset_Get = 'Asset.Get',
    Asset_GetAll = 'Asset.GetAll',

    // AssetServiceUser
    AssetServiceUser_Create = 'AssetServiceUser.Create',
    AssetServiceUser_Update = 'AssetServiceUser.Update',
    AssetServiceUser_Delete = 'AssetServiceUser.Delete',
    AssetServiceUser_Get = 'AssetServiceUser.Get',
    AssetServiceUser_GetAll = 'AssetServiceUser.GetAll',

    // Vehicle
    Vehicle_Create = 'Vehicle.Create',
    Vehicle_Update = 'Vehicle.Update',
    Vehicle_Get = 'Vehicle.Get',
    Vehicle_GetAll = 'Vehicle.GetAll',
    Vehicle_Delete = 'Vehicle.Delete',

    // VehicleAdditionalData
    VehicleAdditionalData_Create = 'VehicleAdditionalData.Create',
    VehicleAdditionalData_Update = 'VehicleAdditionalData.Update',
    VehicleAdditionalData_Get = 'VehicleAdditionalData.Get',
    VehicleAdditionalData_GetAll = 'VehicleAdditionalData.GetAll',
    VehicleAdditionalData_Delete = 'VehicleAdditionalData.Delete',

    // InputCard
    InputCard_Create = 'InputCard.Create',
    InputCard_Update = 'InputCard.Update',
    InputCard_Get = 'InputCard.Get',
    InputCard_GetAll = 'InputCard.GetAll',

    // PersonalDataFamilyMember
    PersonalDataFamilyMember_Create = 'PersonalDataFamilyMember.Create',
    PersonalDataFamilyMember_Update = 'PersonalDataFamilyMember.Update',
    PersonalDataFamilyMember_Get = 'PersonalDataFamilyMember.Get',
    PersonalDataFamilyMember_GetAll = 'PersonalDataFamilyMember.GetAll',
    PersonalDataFamilyMember_Delete = 'PersonalDataFamilyMember.Delete',

    // SchoolWork
    SchoolWork_Create = 'SchoolWork.Create',
    SchoolWork_Update = 'SchoolWork.Update',
    SchoolWork_Get = 'SchoolWork.Get',
    SchoolWork_GetAll = 'SchoolWork.GetAll',

    // HealthSocialWelfareService
    HealthSocialWelfareService_Create = 'HealthSocialWelfareService.Create',
    HealthSocialWelfareService_Update = 'HealthSocialWelfareService.Update',
    HealthSocialWelfareService_Get = 'HealthSocialWelfareService.Get',
    HealthSocialWelfareService_GetAll = 'HealthSocialWelfareService.GetAll',

    // Autonomy
    Autonomy_Create = 'Autonomy.Create',
    Autonomy_Update = 'Autonomy.Update',
    Autonomy_Get = 'Autonomy.Get',
    Autonomy_GetAll = 'Autonomy.GetAll',

    // MaintenanceEvent
    MaintenanceEvent_Create = 'MaintenanceEvent.Create',
    MaintenanceEvent_Update = 'MaintenanceEvent.Update',
    MaintenanceEvent_Get = 'MaintenanceEvent.Get',
    MaintenanceEvent_GetAll = 'MaintenanceEvent.GetAll',
    MaintenanceEvent_Delete = 'MaintenanceEvent.Delete',

    // VehicleServiceUser
    VehicleServiceUser_Create = 'VehicleServiceUser.Create',
    VehicleServiceUser_Update = 'VehicleServiceUser.Update',
    VehicleServiceUser_Get = 'VehicleServiceUser.Get',
    VehicleServiceUser_GetAll = 'VehicleServiceUser.GetAll',
    VehicleServiceUser_Delete = 'VehicleServiceUser.Delete',

    // Verbals
    Verbal_Create = 'Verbal.Create',
    Verbal_Update = 'Verbal.Update',
    Verbal_Delete = 'Verbal.Delete',
    Verbal_Get = 'Verbal.Get',
    Verbal_GetAll = 'Verbal.GetAll',
    Verbal_EditAfterConfirmed = 'Verbal.EditAfterConfirmed',

    // VerbalTags
    VerbalTag_Create = 'VerbalTag.Create',
    VerbalTag_Update = 'VerbalTag.Update',
    VerbalTag_Delete = 'VerbalTag.Delete',
    VerbalTag_Get = 'VerbalTag.Get',
    VerbalTag_GetAll = 'VerbalTag.GetAll',
}
